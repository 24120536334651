.tours-page {
  background-image: url("/assets/img/fondoNegro.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.reservation-form {
  background: rgba(0, 0, 0, 0.5);
  color: #ffa500;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-left {
  border-right: 2px solid #ffa500;
}

.reservation-title {
  font-family: "CustomFont", sans-serif;
  font-size: 2.5rem;
  color: #ffa500;
}

.form-label {
  font-size: 1.2rem;
  color: #ffa500;
}

.form-control {
  background: #ffa500;
  color: #000;
  border: none;
  border-radius: 0;
}

.submit-button {
  background: #ffa500;
  border: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.image-button img {
  display: block;
  width: 100%;
  max-width: 300px;
  height: auto;
}

.image-button:hover img {
  opacity: 0.8;
}

.whatsapp-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.contact-info {
  text-align: center;
}

.contact-info .logo img {
  width: 100px;
  margin-bottom: 20px;
}

.contact-info address,
.contact-info .contact-details p {
  font-size: 1rem;
  color: #ffa500;
}

.contact-info .contact-details p {
  margin: 5px 0;
}
