.title-text {
  font-size: 2.5rem;
  font-weight: bold;
}

.orange-text {
  color: #ff6600;
}

.size-1-4 {
  font-size: 1.4em;
}

.shuttle-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
