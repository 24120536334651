@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@font-face {
  font-family: "space-adventure";
  src: url(/static/media/SpaceAdventure.439cea19.otf) format("opentype");
}

@font-face {
  font-family: "work-sans";
  src: url(/static/media/WorkSans.e9569dcf.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "work-sans", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.space-adventure {
  font-family: "space-adventure";
}

.orange-bg {
  background-color: #f18601 !important;
}

.btn-warning {
  background-color: #f18601 !important;
  color: white !important;
}

.orange-text {
  color: #f18601;
}

.title-text {
  font-size: 6em;
}

.font-weight-bold {
  font-weight: bold;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.content {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 17em;
}

.centered-logo {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .video-background video {
    display: none;
  }

  .video-background {
    background-image: url("/assets/img/mobile.jpg");
    background-size: cover;
    background-position: center;
  }
}

.tours-page {
  background-image: url("/assets/img/fondoNegro.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.tour-card {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.tour-card img {
  border-radius: 5px;
}

.logo-ctn {
  width: 20%;
}

.orange-font {
  color: #f38b01;
}

.wolf-font {
  font-family: "WolfFont", sans-serif;
}

.text-left {
  text-align: left;
}

h1.orange-font {
  font-size: 4em;
}

@media (max-width: 576px) {
  h1.orange-font {
    font-size: 3em;
  }
}

.arrow-white-ctn {
  width: 3%;
}

.arrow-img {
  width: 100%;
  height: 100%;
}

.tours-page {
  background-image: url("/assets/img/fondoNegro.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.reservation-form {
  background: rgba(0, 0, 0, 0.5);
  color: #ffa500;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-left {
  border-right: 2px solid #ffa500;
}

.reservation-title {
  font-family: "CustomFont", sans-serif;
  font-size: 2.5rem;
  color: #ffa500;
}

.form-label {
  font-size: 1.2rem;
  color: #ffa500;
}

.form-control {
  background: #ffa500;
  color: #000;
  border: none;
  border-radius: 0;
}

.submit-button {
  background: #ffa500;
  border: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.image-button img {
  display: block;
  width: 100%;
  max-width: 300px;
  height: auto;
}

.image-button:hover img {
  opacity: 0.8;
}

.whatsapp-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.whatsapp-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.contact-info {
  text-align: center;
}

.contact-info .logo img {
  width: 100px;
  margin-bottom: 20px;
}

.contact-info address,
.contact-info .contact-details p {
  font-size: 1rem;
  color: #ffa500;
}

.contact-info .contact-details p {
  margin: 5px 0;
}

.title-text {
  font-size: 2.5rem;
  font-weight: bold;
}

.orange-text {
  color: #ff6600;
}

.size-1-4 {
  font-size: 1.4em;
}

.shuttle-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.tours-page {
  background-image: url("/assets/img/fondoNegro.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.reservation-form {
  background: rgba(0, 0, 0, 0.5);
  color: #ffa500;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-left {
  border-right: 2px solid #ffa500;
}

.reservation-title {
  font-family: "CustomFont", sans-serif;
  font-size: 2.5rem;
  color: #ffa500;
}

.form-label {
  font-size: 1.2rem;
  color: #ffa500;
}

.form-control {
  background: #ffa500;
  color: #000;
  border: none;
  border-radius: 0;
}

.submit-button {
  background: #ffa500;
  border: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.image-button img {
  display: block;
  width: 100%;
  max-width: 300px;
  height: auto;
}

.image-button:hover img {
  opacity: 0.8;
}

.whatsapp-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.contact-info {
  text-align: center;
}

.contact-info .logo img {
  width: 100px;
  margin-bottom: 20px;
}

.contact-info address,
.contact-info .contact-details p {
  font-size: 1rem;
  color: #ffa500;
}

.contact-info .contact-details p {
  margin: 5px 0;
}

.subtour-menu-page {
  background-image: url("/assets/img/fondoNaranja.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.subtour-menu-page h4 {
  cursor: pointer;
  color: #f38b01;
}

.subtour-card img {
  border-radius: 8px;
}

.subtour-card h5 {
  color: #f38b01;
}

.subtour-menu-page h1 {
  font-size: 4em;
}

@media (max-width: 576px) {
  .subtour-menu-page h1 {
    font-size: 3em;
  }
}

.arrow-ctn {
  width: 6%;
}

.arrow-img {
  width: 100%;
  height: 100%;
}

.white-menu-logo {
  width: 20%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

@media (min-width: 1200px) {
  .white-menu-logo {
    padding-top: 20px;
  }
}

@media (max-width: 576px) {
  .white-menu-logo {
    width: 40%;
  }
}

.navbar-custom {
  background-color: transparent;
  width: 100%;
}

.navbar-brand-custom {
  display: flex;
  align-items: center;
  padding: 0;
}

.logo-container {
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}


.second-subtour-menu-page {
  background-image: url("/assets/img/fondoAmarillo.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.subtour-card {
  cursor: pointer;
  transition: transform 0.2s;
}

.subtour-card:hover {
  transform: scale(1.05);
}

.subtour-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.subtour-card h5 {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}


.body_construction{
    background-image: url(/assets/img/fondoNegro.png);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .p-3 {
    display: none;
} */
.content_constru{
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
.logo_constru{
    width: 150px;
}
.title_constru{
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    margin-top: -18px;
}
.descrip_constru{
    color: #fff;
    margin-bottom: 22px;
}
.button_more{
    background-color: #f18603;
    text-decoration: none;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    display: inline-block;
}
.button_more:hover{
    background-color: #ecd802;
    color: #000;
}
@font-face {
  font-family: "WildWolf";
  src: url(/static/media/WildWolf.e9220824.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.orange-bg {
  background-color: #f38b01;
}

.orange-font {
  color: #f38b01;
}

.h-100vh {
  height: 100vh;
}

.wolf-font {
  font-family: "WildWolf", sans-serif;
}

.social-icons {
  position: absolute;
  bottom: 28px;
}

.menu-text-wolf {
  font-size: 2.7em;
}

.full-width-img {
  width: 100%;
  object-fit: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-button {
  background-color: #fff;
  color: #333;
  border: none;
}

.logo-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.menu-button {
  background-color: transparent;
  border: none;
  color: white;
}

.hamburger-menu-button {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  outline: none !important;
}

.hamburger-menu-button:focus {
  outline: none;
  box-shadow: none;
}

.btn-transparent {
  background-color: transparent;
  color: white;
}

.text-content {
  padding-bottom: 50px;
}

.btn-no-border {
  border: none;
  outline: none;
}

.offcanvas-header .btn-close {
  filter: invert(1);
}

.menu-container {
  opacity: 0.8;
}

.img-menu-container {
  width: 30%;
}

.menu-button .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

