@font-face {
  font-family: "WildWolf";
  src: url("./fonts/WildWolf.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.orange-bg {
  background-color: #f38b01;
}

.orange-font {
  color: #f38b01;
}

.h-100vh {
  height: 100vh;
}

.wolf-font {
  font-family: "WildWolf", sans-serif;
}

.social-icons {
  position: absolute;
  bottom: 28px;
}

.menu-text-wolf {
  font-size: 2.7em;
}

.full-width-img {
  width: 100%;
  object-fit: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-button {
  background-color: #fff;
  color: #333;
  border: none;
}

.logo-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.menu-button {
  background-color: transparent;
  border: none;
  color: white;
}

.hamburger-menu-button {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  outline: none !important;
}

.hamburger-menu-button:focus {
  outline: none;
  box-shadow: none;
}

.btn-transparent {
  background-color: transparent;
  color: white;
}

.text-content {
  padding-bottom: 50px;
}

.btn-no-border {
  border: none;
  outline: none;
}

.offcanvas-header .btn-close {
  filter: invert(1);
}

.menu-container {
  opacity: 0.8;
}

.img-menu-container {
  width: 30%;
}

.menu-button .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
