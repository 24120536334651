.second-subtour-menu-page {
  background-image: url("/assets/img/fondoAmarillo.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.subtour-card {
  cursor: pointer;
  transition: transform 0.2s;
}

.subtour-card:hover {
  transform: scale(1.05);
}

.subtour-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.subtour-card h5 {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}
