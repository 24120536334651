.subtour-menu-page {
  background-image: url("/assets/img/fondoNaranja.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.subtour-menu-page h4 {
  cursor: pointer;
  color: #f38b01;
}

.subtour-card img {
  border-radius: 8px;
}

.subtour-card h5 {
  color: #f38b01;
}

.subtour-menu-page h1 {
  font-size: 4em;
}

@media (max-width: 576px) {
  .subtour-menu-page h1 {
    font-size: 3em;
  }
}
