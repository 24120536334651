.tours-page {
  background-image: url("/assets/img/fondoNegro.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px 0;
}

.tour-card {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.tour-card img {
  border-radius: 5px;
}

.logo-ctn {
  width: 20%;
}

.orange-font {
  color: #f38b01;
}

.wolf-font {
  font-family: "WolfFont", sans-serif;
}

.text-left {
  text-align: left;
}

h1.orange-font {
  font-size: 4em;
}

@media (max-width: 576px) {
  h1.orange-font {
    font-size: 3em;
  }
}
