.body_construction{
    background-image: url(/assets/img/fondoNegro.png);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .p-3 {
    display: none;
} */
.content_constru{
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
.logo_constru{
    width: 150px;
}
.title_constru{
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    margin-top: -18px;
}
.descrip_constru{
    color: #fff;
    margin-bottom: 22px;
}
.button_more{
    background-color: #f18603;
    text-decoration: none;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    display: inline-block;
}
.button_more:hover{
    background-color: #ecd802;
    color: #000;
}