.navbar-custom {
  background-color: transparent;
  width: 100%;
}

.navbar-brand-custom {
  display: flex;
  align-items: center;
  padding: 0;
}

.logo-container {
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}
