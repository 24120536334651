.white-menu-logo {
  width: 20%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

@media (min-width: 1200px) {
  .white-menu-logo {
    padding-top: 20px;
  }
}

@media (max-width: 576px) {
  .white-menu-logo {
    width: 40%;
  }
}
