.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.content {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 17em;
}

.centered-logo {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .video-background video {
    display: none;
  }

  .video-background {
    background-image: url("/assets/img/mobile.jpg");
    background-size: cover;
    background-position: center;
  }
}
