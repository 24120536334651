@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "space-adventure";
  src: url("./fonts/SpaceAdventure.otf") format("opentype");
}

@font-face {
  font-family: "work-sans";
  src: url("./fonts/WorkSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "work-sans", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.space-adventure {
  font-family: "space-adventure";
}

.orange-bg {
  background-color: #f18601 !important;
}

.btn-warning {
  background-color: #f18601 !important;
  color: white !important;
}

.orange-text {
  color: #f18601;
}

.title-text {
  font-size: 6em;
}

.font-weight-bold {
  font-weight: bold;
}
